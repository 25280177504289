import react from 'react'


function Php() {
    


    return (
        <div>
            <div>php</div>
            <div>Version</div>
        </div>
    );
}

export default Php;