import React, { useState, useEffect } from "react";
import "./LatestVersions.css";
import Php from "./ProgrammingLanguages/Php";
import logo from "../Resources/api_software_logo.svg"
import ParentComponent from "./ParentComponent";


function LatestVersions() {
  const [components, setComponents] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [goVersion, setGoVersion] = useState("");
  const [pythonVersion, setPythonVersion] = useState("");
  const [csharpVersion, setCsharpVersion] = useState("");
  const [javascriptVersion, setJavascriptVersion] = useState("1.8.5");
  const [javaVersion, setJavaVersion] = useState("");
  const [swiftVersion, setSwiftVersion] = useState("");
  const [cplusplusVersion, setCplusplusVersion] = useState("");
  const [rustVersion, setRustVersion] = useState("");
  const [rubyVersion, setRubyVersion] = useState("");
  const [phpVersion, setPhpVersion] = useState("");
  

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const languageData = [
    { name: "Go", version: goVersion },
    { name: "Python", version: pythonVersion },
    { name: "JavaScript", version: javascriptVersion },
    { name: "Ruby", version: rubyVersion },
    { name: "Java", version: javaVersion },
    { name: "Swift", version: swiftVersion },
    { name: "C++", version: cplusplusVersion },
    { name: "Rust", version: rustVersion },
    { name: "C#", version: csharpVersion },
    { name: "PHP", version: phpVersion },
  ];
    
  async function addComponent(componentName) {
    const component = await import(`./ProgrammingLanguages/${componentName}`);
    setComponents(prevComponents => [...prevComponents, component.default]);
  }  
  
  const fetchGoVersion = async () => {
    try {
      const response = await fetch("https://go.dev/dl/?mode=json");
      const data = await response.json();
      const latestGoVersion = data[0].version;
      setGoVersion(latestGoVersion);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPythonVersion = async () => {
    try{
      const response = await fetch("https://api.github.com/repos/python/cpython/tags")
      const data = await response.json();
      setPythonVersion(data[0].name);
    } catch(error) {
      console.error(error)
    } 
  };


  const fetchPhpVersion = async () => {
    try{
      const response = await fetch("https://api.github.com/repos/php/php-src/tags")
      const data = await response.json();
      setPhpVersion(data[0].name);
    }
    catch(error) {
      console.error(error)
    }
  }

  const fetchCplusplusVersion = async () => {
    try{
      const response = await fetch("https://api.github.com/repos/gcc-mirror/gcc/tags")
      const data = await response.json();
      setCplusplusVersion(data[0].name);
    }
    catch(error) {
      console.error(error)
    }
  }
  
  const fetchRubyVersion = async () => {
    try{
      const response = await fetch("https://api.github.com/repos/ruby/ruby/tags")
      const data = await response.json();
      setRubyVersion(data[0].name);
    } catch(error) {
      console.error(error)
    } 
  };

  const fetchCsharpVersion = async () => {
    try{
      const response = await fetch('https://api.nuget.org/v3-flatcontainer/microsoft.net.compilers/index.json')
      const data = await response.json()
      const latestVersion = data.versions[data.versions.length - 1];
      setCsharpVersion(latestVersion)
    } catch(error) {
      console.error(error)
    } 
  };

  const fetchJavaVersion = async () => {
    try{
      const response = await fetch("https://api.github.com/repos/openjdk/jdk/tags")
      const data = await response.json();
      setJavaVersion(data[0].name);
    }
    catch(error) {
      console.error(error)
    } 
  }

  const fetchSwiftVersion = async () => {
    try{

      const response = await fetch("https://api.github.com/repos/apple/swift/releases")
      const data = await response.json();
      setSwiftVersion(data[0].name);
    }
    catch(error) {
      console.error(error)
    }
  }

  const fetchRustVersion = async () => {
    try{
      const response = await fetch("https://api.github.com/repos/rust-lang/rust/tags")
      const data = await response.json();
      setRustVersion(data[0].name);
    }
    catch(error) {
      console.error(error)
    }
  }


  useEffect(() => {
    fetchGoVersion();
    fetchPythonVersion();
    fetchRubyVersion();
    fetchCsharpVersion();
    fetchPhpVersion();
    fetchCplusplusVersion();
    fetchSwiftVersion();
    fetchJavaVersion();
    fetchRustVersion();
  }, [goVersion, 
      pythonVersion,
      csharpVersion,
      cplusplusVersion, 
      javascriptVersion,
      javaVersion, 
      rubyVersion, 
      phpVersion,
      rustVersion,
      swiftVersion,]);

  const filteredLanguages = languageData.filter((language) =>
    language.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentYear = new Date().getFullYear();

  
  return (
    <div className="app-container">
      <header className="header">
        <img
          src={logo}
          alt="ApiSoftwarelogo"
        />
        <h1>Programming Language Versions</h1>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </header>
      <main className="main">
        <table className="languages">
          <thead>
            <tr>
              <th>Language</th>
              <th>Version</th>
            </tr>
          </thead>
          <tbody>
            {filteredLanguages.map((language) => (
              <tr key={language.name}>
                <td>{language.name}</td>
                <td>{language.version}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
      <footer className="footer">
        <p>© {currentYear} IT Dienstleistung KS. All rights reserved.</p>
      </footer>



      {/* <ParentComponent /> */}


     </div>
  );
}

export default LatestVersions;
